nav {
    background: linear-gradient(to bottom, #131b41, #268cf1);
}

.navbar_nav {
    display: flex;
    justify-content: left;
    align-items: center;
    padding-top: 5px;
    padding-bottom: 5px;
}

.menu {
    display: flex;
    gap: 20px;
    margin-bottom: 0;
}

li {
    display: flex;
    align-items: center;
    list-style-type: none;
    margin: 20px 20px 20px 20px;
}

a {
    color: rgb(212, 212, 216);
    text-decoration: none;
}

nav a:hover,
nav a.active {
    color: #fafafa;
    font-weight: 400;
}

.logo_img {
    width: 50px;
    height: 50px;
    margin: 5px 10px 5px 30px;
}

.small_img {
    width: 17px;
    height: 17px;
    margin-right: 5px;
}
