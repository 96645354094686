.wrapper {
    background: linear-gradient(to bottom, #131b41, #268cf1, #131b41);
    color: white;
}

.row {
    justify-content: center;
    height: 100vh;
}

.title {
    display: flex;
    align-items: center;
    justify-content: center;
    font: bold 30px "Garamond";
    color: #d8eafd;
}

.btn-primary {
    height: 40px;
    margin: 20px;
    border: 2px solid #66b0f9;
    background-color: #66b0f9;
}

.container_lang {
    height: 50px;
    margin: 10px 0px 10px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.lang_img {
    display: block;
    width: 60px;
}

.initialWord,
.translation,
.translation:focus {
    margin: 5px;
    background-color: #66b0f9;
    border: 1px solid #66b0f9;
    color: white;
    position: relative;
    top: 20px;
}

.initialWord {
    height: 38px;
}

.a3,
.a4 {
    height: 50px;
    position: relative;
    bottom: 50px;
}

.error {
    color: #b7d7f7;
    font-weight: 600;
    height: 20px;
    margin: 10px;
    display: flex;
    justify-content: center;
    position: relative;
    bottom: 70px;
}

.container_balls {
    display: flex;
    justify-content: center;
    position: relative;
    bottom: 100px;
}

.ball {
    width: 60px;
    height: 60px;
}

.ball_nz2_img,
.ball_am3_img,
.ball_gb2_img,
.ball_am_img {
    margin: 20px 20px 0px 20px;
}

.ball_nz_img,
.ball_can2_img,
.ball_au_img,
.ball_gb_img {
    margin: 70px 20px 0px 20px;
}

@media (max-width: 650px) {
    .title_img {
        width: 500px;
        height: 85px;
    }
}

@media (max-width: 520px) {
    .title_img {
        width: 400px;
        height: 70px;
    }
}

@media (max-width: 420px) {
    .title_img {
        width: 300px;
        height: 65px;
    }
}
